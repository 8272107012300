import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { cn } from "../utils";

const CustomTooltip = ({ className, children, content, maxWidth, place }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    offset: [0, 10],
    placement: place,
  });

  return (
    <>
      <button
        className={cn(className)}
        style={{ height: "fit-content" }}
        type="button"
        ref={setTriggerRef}
      >
        {children}
      </button>

      {visible && (
        <div
          ref={setTooltipRef}
          style={{
            maxWidth,
            padding: "4px",
            width: "fit-content",
            boxShadow: "0px 10px 100px rgba(60, 66, 87, 0.2)",
          }}
          {...getTooltipProps({ className: "resala-tooltip-container" })}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
        </div>
      )}
    </>
  );
};

export default CustomTooltip;
