import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ResalaLogo from '../assets/images/Navbar/Resala Logo.svg';
import ResalaLogoArabic from '../assets/images/Navbar/Resala Logo - Arabic.svg';
import facebookImg from '../assets/images/Footer/facebook.svg';
import TiktokImg from '../assets/images/Footer/Tiktok.svg';
import TwitterImg from '../assets/images/Footer/Twitter.svg';
import YoutubeImg from '../assets/images/Footer/Youtube.svg';
import InstagramImg from '../assets/images/Footer/Instagram.svg';
import facebookImgBlue from '../assets/images/Footer/facebook-svgrepo-com.svg';
import TiktokImgBlue from '../assets/images/Footer/Tiktok-svg.svg';
import TwitterImgBlue from '../assets/images/Footer/Twitter-svgrepo-com.svg';
import YoutubeImgBlue from '../assets/images/Footer/Youtube-svgrepo-com.svg';
import InstagramImgBlue from '../assets/images/Footer/Instagram-svgrepo-com.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UAEImg from '../assets/images/Footer/UAE_Flag.svg';
import { ButtonFilled } from './Button';
import { SubscribeEmail } from '../api';
import { ServerErrorModal } from './RequestModal';
import { checkLanguage } from '../Pages/Extension/Components/utils';

const Footer = ({ handleClickScroll, referrer }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isArabic = i18n.language === "ar";
  const [socialImages, setSocialImages] = useState([
    {
      image: facebookImg,
      link: 'https://www.facebook.com/resalaai',
      hoverImage: facebookImgBlue,
    },
    {
      image: TiktokImg,
      link: 'https://www.tiktok.com/@resalaai',
      hoverImage: TiktokImgBlue,
    },
    {
      image: TwitterImg,
      link: 'https://x.com/resalaai',
      hoverImage: TwitterImgBlue,
    },
    {
      image: InstagramImg,
      link: 'https://www.instagram.com/resalaai/',
      hoverImage: InstagramImgBlue,
    },
    {
      image: YoutubeImg,
      link: 'https://www.youtube.com/@resalaai',
      hoverImage: YoutubeImgBlue,
    },
  ]);
  const navigate = useNavigate();
  const [footerMenu, setFooterMenu] = useState([]);

  useEffect(() => {
    // Update lists when language changes
    setFooterMenu(getTranslatedList(t));
  }, [t]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(undefined);
  const [serverError, setServerError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubscribe = async () => {
    setIsLoading(true);
    const isValid = /^[\w\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
    if (email?.length === 0 || !isValid) {
      setErrMsg(isArabic ? 'البريد الإلكتروني غير صالح':'Invalid Email');
      setIsLoading(false);
      return;
    }
    try {
      const payload = {
        email: email.trim(),
        language: i18n.language === 'en' ? 'English' : 'arabic',
        ...(referrer !== undefined &&
          referrer !== '' && { referral_url: referrer }),
      };
      const response = await SubscribeEmail(payload);
      if (response.status === 500) {
        setServerError(true);
        setIsLoading(false);
        return;
      } else if (response.status === 400) {
        setErrMsg(response.data.Message);
        setIsLoading(false);
        return;
      }
      setEmail('');
      setIsSuccess(true);
      setIsLoading(false);
      setTimeout(() => setIsSuccess(false), 5000);
    } catch (err) {
      setIsLoading(false);
      setEmail('');
      console.log('error', err);
    }
  };

  return (
    <>
      <div
        className={`${
          location.pathname === '/terms' || location.pathname === '/privacy'
            ? 'bg-[#F3F4F8]'
            : ''
        }`}
      >
        <div className=" m-auto max-w-[1040px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] max_sm:pb-[0px] py-[60px]">
          <div className="flex max_sm:pb-[40px] max_md:flex-col max_md:gap-[60px] max_350:gap-12">
            {/* Logo and Social Media Icons */}
            <div className="w-[30%] max_md:w-[100%]">
              <div>
                <img
                  src={i18n.language === 'en' ? ResalaLogo : ResalaLogoArabic}
                  alt="Logo"
                />
              </div>
              <div className="text-TextSmall font-DMSansFont font-medium text-LightTextParagraph my-10 max_sm:text-MediumText">
                {t('ResalaFooterText')}
              </div>
              <div className="flex gap-[6px]">
                {socialImages.map((data, index) => {
                  return (
                    <React.Fragment key={index + 1}>
                      <div className="cursor-pointer">
                        <a
                          href={data.link}
                          rel="noopener noreferrer"
                          target="_blank"
                          onMouseOver={() => setHoveredIndex(index)}
                          onMouseOut={() => setHoveredIndex(null)}
                        >
                          <img
                            src={
                              index === hoveredIndex
                                ? data.hoverImage
                                : data.image
                            }
                            alt=""
                          />
                        </a>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>

            {/* Menu Option */}
            <div className="w-[70%] max_md:w-[100%]">
              <div className="w-[100%] flex gap-[20%] justify-end max_md:justify-start max_md:gap-[15%] max_sm:gap-[10%] max_sm_380:gap-[5%] max_350:flex-wrap max_350:gap-[20%]">
                {footerMenu.map((mainMenu, index1) => {
                  return (
                    <React.Fragment key={index1 + 1}>
                      <div className="flex flex-col gap-4 max_350:mt-3 h-max">
                        <div
                          style={{ letterSpacing: '-1px' }}
                          className="font-bold discoverHeading font-DMSansFont"
                        >
                          {mainMenu.menuHeading}
                        </div>

                        {mainMenu.options?.map((menuOption, index2) => {
                          return (
                            <React.Fragment key={index2 + 1}>
                              <>
                                {menuOption.name === t('ContactUs') ? (
                                  <>
                                    <a
                                      className="flex"
                                      href="mailto:support@resala.ai"
                                      onClick={() =>
                                        'window.location=another.html'
                                      }
                                    >
                                      <div className="cursor-pointer text-TextSmall font-DMSansFont font-medium text-LightTextParagraph max_sm:text-MediumText hover:text-LightPrimary">
                                        {menuOption.name}
                                      </div>
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="cursor-pointer text-TextSmall font-DMSansFont font-medium text-LightTextParagraph max_sm:text-MediumText hover:text-LightPrimary"
                                      onClick={() => {
                                        if (menuOption.name === t('Features')) {
                                          handleClickScroll('featureScrollId');
                                        } else if (
                                          menuOption.name === t('HowItWorks')
                                        ) {
                                          handleClickScroll('HowItWorksId');
                                        } else {
                                          navigate(menuOption.link);
                                          setTimeout(() => {
                                            window.scrollTo(0,0)
                                          },200)
                                        }
                                      }}
                                    >
                                      {menuOption.name}
                                    </div>
                                  </>
                                )}
                              </>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <div
                className={`h-[54%] max_md:h-auto max_md:mt-4 flex items-end max_md:justify-start ${
                  i18n.language === 'en'
                    ? 'justify-start ml-[17%] max_xl:ml-[14%] max_md:ml-0'
                    : 'justify-end max_xl:ml-0 mr-[28%] max_xl:mr-[25%] max_md:mr-0'
                }`}
              >
                <div className="flex relative justify-between w-[100%] max_md:w-[66%] max_800:w-[90%] max_sm:w-[100%] h-[50px] max_md:h-[40px]">
                  <div className="w-[calc(100%-130px)] max_sm:w-[calc(100%-90px)]">
                    <input
                      className={`w-[100%] h-full outline-none px-[12px] text-LightPrimary text-TextSmall font-DMSansFont font-normal border-b-[1px] border-t-[1px]  ${
                        errMsg ? 'border-ErrorText' : 'border-[#DFE4EC]'
                      } ${
                        i18n.language === 'en'
                          ? `rounded-tl-[10px] rounded-bl-[10px] border-l-[1px]`
                          : 'rounded-tr-[10px] rounded-br-[10px] border-r-[1px]'
                      }`}
                      dir={
                        email.length
                          ? isArabic && checkLanguage(email)
                            ? "rtl"
                            : "ltr"
                          : isArabic
                          ? "rtl"
                          : "ltr"
                      }
                      placeholder={t('EnterYourEmail')}
                      value={email}
                      onChange={(e) => {
                        setErrMsg(undefined);
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className={`absolute F-JC-AI-CENTER w-[130px] max_sm:w-[90px] h-[100%] hover:bg-[#1263CB] cursor-pointer ${
                      isLoading ? 'bg-[#CED8E8]' : 'bg-[#1678F2]'
                    }  ${
                      i18n.language === 'en'
                        ? 'rounded-tr-[10px] rounded-br-[10px] right-[20px]'
                        : 'rounded-tl-[10px] rounded-bl-[10px] left-[20px]'
                    }`}
                    disabled={isLoading}
                    onClick={handleSubscribe}
                  >
                    <div className="font-DMSansFont font-medium text-white text-TextMedium max_sm:text-TextSmall">
                      {t('Subscribe')}
                    </div>
                  </button>
                  {errMsg && (
                    <div className="absolute top-[100%] left-0">
                      <div className="flex text-ErrorText gap-[4px] items-center mt-[3px] text-[14px]">
                        <ErrorIcon />
                        <p>{errMsg}</p>
                      </div>
                    </div>
                  )}
                  {isSuccess && (
                    <div className="absolute top-[100%] left-0">
                      <div className="flex text-SuccessText gap-[4px] items-center mt-[3px] text-[14px]">
                        <SuccesIcon />
                        <p>{t('SuccessSubscribe')}</p>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div
                className={` ${
                  i18n.language === 'en'
                    ? 'w-[520px] max_xl:w-[85%]'
                    : 'w-[520px] max_xl:w-[72%]'
                }
                  max_md:w-[80%] max_sm:w-[95%] border-[1px] border-[#DFE4EC] rounded-[10px] flex justify-between`}
              >
                <div className="w-[100%] h-[58px] flex items-center p-1">
                  <input
                    className={`w-[100%] outline-none px-[12px] text-LightPrimary text-TextSmall font-DMSansFont font-normal`}
                    placeholder={t('EnterYourEmail')}
                  />
                </div>
                <div className="w-[130px] h-full border-2">
                  k
                  
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footerBackground border-t-[1px] border-[#DFE4EC]">
          <div className="m-auto max-w-[1040px] max_xl:max-w-[980px] max_md:px-[40px] max_sm:px-[20px] py-[18px] flex justify-between text-TextSmall text-LightPrimary font-DMSansFont font-medium max_sm:text-[12px] max_sm:py-3 max_sm:flex-col max_sm:items-center">
            <div className="flex gap-2">
              <div>{t('MadeWithDubai')} </div>
              <div className="w-[26px] max_sm:w-[18px]">
                <img
                  src={UAEImg}
                  style={{ width: '100%', height: '100%' }}
                ></img>
              </div>
            </div>
            <div className="">
              {t('AllRightsReserved', { year: new Date().getFullYear() })}
            </div>
          </div>
        </div>
      </div>

      {serverError && (
        <div
          id="static-modal"
          data-modal-backdrop="static"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-[60] flex justify-center items-center h-screen bg-opacity-50 bg-black"
          onClick={() => setServerError(false)}
        >
          <ServerErrorModal onClose={() => setServerError(false)} />
        </div>
      )}
    </>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    {
      menuHeading: t('About'),
      options: [
        {
          name: t('Feature'),
          link: '',
        },
        {
          name: t('Pricing'),
          link: '/pricing',
        },
      ],
    },
    {
      menuHeading: t('Help'),
      options: [
        {
          name: t('ContactUs'),
          link: '',
        },
        {
          name: t('HowItWorks'),
          link: '',
        },
      ],
    },
    {
      menuHeading: t('Legal'),
      options: [
        {
          name: t('PrivacyPolicy'),
          link: '/privacy',
        },
        {
          name: t('TermsConditions'),
          link: '/terms',
        },
      ],
    },
  ];

  return lists;
};
export default Footer;

{
  /* <ButtonFilled
                    title={t('Subscribe')}
                    rounded="10px"
                    height="60px"
                  /> */
}

const ErrorIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.99967 14.6666C11.6663 14.6666 14.6663 11.6666 14.6663 7.99992C14.6663 4.33325 11.6663 1.33325 7.99967 1.33325C4.33301 1.33325 1.33301 4.33325 1.33301 7.99992C1.33301 11.6666 4.33301 14.6666 7.99967 14.6666Z"
      stroke="#FF0000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 5.33325V8.66659"
      stroke="#FF0000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99609 10.6667H8.00208"
      stroke="#FF0000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SuccesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.99967 15.1668C4.04634 15.1668 0.833008 11.9535 0.833008 8.00016C0.833008 4.04683 4.04634 0.833496 7.99967 0.833496C11.953 0.833496 15.1663 4.04683 15.1663 8.00016C15.1663 11.9535 11.953 15.1668 7.99967 15.1668ZM7.99967 1.8335C4.59967 1.8335 1.83301 4.60016 1.83301 8.00016C1.83301 11.4002 4.59967 14.1668 7.99967 14.1668C11.3997 14.1668 14.1663 11.4002 14.1663 8.00016C14.1663 4.60016 11.3997 1.8335 7.99967 1.8335Z"
      fill="#23B339"
    />
    <path
      d="M7.05297 10.3867C6.91964 10.3867 6.79297 10.3334 6.69964 10.2401L4.81297 8.3534C4.61964 8.16007 4.61964 7.84007 4.81297 7.64673C5.0063 7.4534 5.3263 7.4534 5.51964 7.64673L7.05297 9.18007L10.4796 5.7534C10.673 5.56007 10.993 5.56007 11.1863 5.7534C11.3796 5.94673 11.3796 6.26673 11.1863 6.46006L7.4063 10.2401C7.31297 10.3334 7.1863 10.3867 7.05297 10.3867Z"
      fill="#23B339"
    />
  </svg>
);
