import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { SubmitRequest, getCount } from "../api";
import { checkLanguage, cn } from "../Pages/Extension/Components/utils";
import SearchIcon from "../assets/images/HomePage/SearchIcon.svg";
import { RequestIcon } from "../assets/images/Navbar/RequestSvg";
import Arrow from "../assets/images/Navbar/arrow-down.png";
import useWindowHeight from "../utils/useHeight";
import Skeleton from "react-loading-skeleton";
import { COUNTRY_JSON } from "../utils/countryList";
import WaitListContext from "../context/waitlistContext";

const RequestModal = ({
  isModalOpen,
  setModalOpen,
  toggleModal,
  hideModal,
  referrer,
}) => {
  const { t, i18n } = useTranslation();
  const windowHeight = useWindowHeight();
  const { waitlistCount, setWaitlistCount } = useContext(WaitListContext);

  const isArabic = i18n.language === "ar";

  const [searchText, setSearchText] = useState("");
  const [countries, setCountries] = useState(COUNTRY_JSON);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(undefined);
  const [occupationSearch, setOccupationSearch] = useState("");
  const [serverError, setServerError] = useState(false);
  const [count, setCount] = useState(undefined);
  const intitalForm = {
    email: "",
    name: "",
    occupation: "",
    country: undefined,
  };
  const [formData, setFormData] = useState(intitalForm);

  const [occupationList, setOccupationList] = useState([]);

  useEffect(() => {
    setOccupationList(getTranslatedList(t));
  }, [t]);

  const filteredCountries = countries.filter((country) =>
    country.name.common.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredOccupation = occupationList.filter((occupation) =>
    occupation.toLowerCase().includes(occupationSearch.toLowerCase())
  );

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  const handleFormChange = (name, inValue) => {
    setFormData({
      ...formData,
      [name]: inValue,
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      setIsSuccess(false);
    }
  }, [isModalOpen]);

  const handleSubmit = async () => {
    setIsLoading(true);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setIsLoading(false);
      setIsError(t("validEmail"));
    } else {
      const payload = {
        email: formData.email.trim(),
        name: formData.name,
        occupation: formData.occupation,
        country: formData.country.name.common,
        language: i18n.language === "en" ? "English" : "arabic",
        ...(referrer !== undefined &&
          referrer !== "" && { referral_url: referrer }),
      };
      try {
        const response = await SubmitRequest(payload);
        if (response.status === 500) {
          setServerError(true);
          return;
        } else if (response.status === 400) {
          setIsError(t("emailWaitListed"));
          return;
        } else if (response.Success) {
          setIsSuccess(true);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
        handleCount();
      }
    }
  };

  const handleCount = () => {
    getCount()
      .then((response) => {
        setWaitlistCount(response.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const isDisable =
    Object.values(formData).some(
      (value) => typeof value === "string" && value.trim() === ""
    ) || Object.values(formData).includes(undefined);

  return (
    <div>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-70" onClose={toggleModal}>
          <div
            className={cn("w-full fixed inset-0 bg-white/30", {})}
            aria-hidden="true"
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={cn("w-full fixed inset-0 bg-black bg-opacity-25", {})}
            />
          </Transition.Child>

          <div className={cn("w-full fixed inset-0 overflow-y-auto", {})}>
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[482px] transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all overflow-visible">
                  <>
                    <div
                      dir={isArabic ? "rtl" : "ltr"}
                      className="w-full max-w-[482px] max-h-full"
                    >
                      {!isSuccess && !serverError && (
                        //  Modal content
                        <div
                          style={{
                            boxShadow:
                              "0px 4px 30px 0px rgba(60, 66, 87, 0.15)",
                          }}
                          className="relative bg-white rounded-lg shadow"
                        >
                          {/* Modal header */}
                          <button
                            onClick={() => {
                              hideModal();
                              setIsError(undefined);
                              setServerError(false);
                              // setValue(null);
                              setIsSuccess(false);
                              setSearchText("");
                              setFormData(intitalForm);
                            }}
                            type="button"
                            className="absolute top-[16px] right-[16px] text-gray-400 bg-transparent hover:bg-gray-200  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>

                          {/* Modal body */}
                          <div className="px-10 py-[30px] max_sm:px-5 max_sm:py-[15px] md:p-5 space-y-4 flex flex-col items-center">
                            <div className="w-[80px] h-[80px]">
                              {/* <img src={RequestImg} alt="Request_access" /> */}
                              <RequestIcon />
                            </div>
                            <div className="text-LightPrimary text-SmallsubHeading font-DMSansFont font-bold tracking-[-1px]">
                              {t("Request")}
                            </div>
                            <div className="text-LightTextParagraph text-TextSmall font-DMSansFont font-medium text-center tracking-[-1px]">
                              {t("WaitListText1")} {""}{" "}
                              {waitlistCount ?? (
                                <Skeleton height={16} width={26} />
                              )}{" "}
                              {t("WaitListText2")}
                            </div>
                            <div
                              className="w-[100%]"
                              dir={isArabic ? "rtl" : "ltr"}
                            >
                              <div
                                style={{
                                  textAlign: isArabic ? "right" : "left",
                                }}
                                className="text-LightStrokActive text-MediumText"
                              >
                                {t("Name")}*
                              </div>

                              <input
                                name="name"
                                dir={
                                  formData.name
                                    ? isArabic && checkLanguage(formData.name)
                                      ? "rtl"
                                      : "ltr"
                                    : isArabic
                                    ? "rtl"
                                    : "ltr"
                                }
                                className="w-[100%] px-[15px] py-[10px] text-MediumText rounded-[7px] border-LightStrokPrimary border-[1px] outline-none font-DMSansFont font-normal text-LightPrimary"
                                placeholder={t("EnterName")}
                                value={formData.name}
                                onChange={(e) =>
                                  handleFormChange("name", e.target.value)
                                }
                              />

                              <div
                                className={`${
                                  isError
                                    ? "text-ErrorText"
                                    : "text-LightStrokActive"
                                } text-MediumText mt-4`}
                                style={{
                                  textAlign: isArabic ? "right" : "left",
                                }}
                              >
                                {t("Email")}*
                              </div>
                              <div dir={isArabic ? "rtl" : "ltr"}>
                                <input
                                  dir={
                                    formData.email
                                      ? isArabic &&
                                        checkLanguage(formData.email)
                                        ? "rtl"
                                        : "ltr"
                                      : isArabic
                                      ? "rtl"
                                      : "ltr"
                                  }
                                  className={`w-[100%] px-[15px] py-[10px] text-MediumText rounded-[7px] ${
                                    isError
                                      ? "border-ErrorText text-LightPrimary"
                                      : "border-LightStrokPrimary text-LightPrimary"
                                  } border-[1px] outline-none font-DMSansFont font-normal `}
                                  placeholder={t("EnterEmail")}
                                  value={formData.email}
                                  onChange={(e) => {
                                    handleFormChange("email", e.target.value);
                                    setIsError(undefined);
                                  }}
                                />
                                {isError && (
                                  <div className="flex text-ErrorText gap-[4px] items-center mt-[3px] text-[14px]">
                                    <ErrorIcon />
                                    <p>{isError}</p>
                                  </div>
                                )}
                              </div>
                              <div
                                style={{
                                  textAlign: isArabic ? "right" : "left",
                                }}
                                className="text-LightStrokActive text-MediumText mt-4"
                              >
                                {t("Occupation")}*
                              </div>

                              <div className="relative">
                                <Listbox>
                                  {({ open }) => {
                                    if (!open) {
                                      setOccupationSearch("");
                                    }
                                    return (
                                      <>
                                        <Listbox.Button
                                          className={cn(
                                            "relative items-center w-[100%] text-[14px] text-LightStrokActive px-[15px] py-[10px] rounded-[7px] border-LightStrokPrimary border-[1px] flex justify-between",
                                            {
                                              "text-LightPrimary":
                                                formData.occupation,
                                            }
                                          )}
                                        >
                                          {formData.occupation
                                            ? formData.occupation
                                            : t("SelectOccupation")}

                                          <img
                                            className={cn(
                                              "absolute top-[13px] transition-all",
                                              {
                                                "rotate-[180deg]": open,
                                                "left-[13px]": isArabic,
                                                "right-[13px]": !isArabic,
                                              }
                                            )}
                                            src={Arrow}
                                            alt="Arrow"
                                          />
                                        </Listbox.Button>

                                        <Listbox.Options
                                          className={cn("hidden", {
                                            block: open,
                                          })}
                                        >
                                          <div
                                            className={cn(
                                              "z-30 pb-[8px] absolute top-11 left-0 shadow-[0px_2px_20px_rgba(0,0,0,0.15)] bg-white w-[100%] rounded-[8px]"
                                            )}
                                          >
                                            <div className="flex items-center px-4 h-[36px]">
                                              <div className="w-fit">
                                                <img
                                                  src={SearchIcon}
                                                  alt="search"
                                                  className=""
                                                />
                                              </div>
                                              <input
                                                className="border-none outline-none w-[100%] px-[10px] py-[2px] text-MediumText"
                                                placeholder={t(
                                                  "SearchOccupation"
                                                )}
                                                value={occupationSearch}
                                                onChange={(e) =>
                                                  setOccupationSearch(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="w-[100%] h-[1px] bg-[#DFE4EC]"></div>
                                            <div className="h-fit max-h-[150px] overflow-y-auto p-2">
                                              {filteredOccupation.map(
                                                (items, index) => {
                                                  return (
                                                    <Listbox.Option
                                                      key={index}
                                                      value={items}
                                                      // disabled={items.unavailable}
                                                    >
                                                      <div
                                                        className="text-LightStrokActive font-DMSansFont font-normal hover:text-LightPrimary hover:bg-LightGray px-2 py-1 rounded-[8px] cursor-pointer"
                                                        onClick={() => {
                                                          handleFormChange(
                                                            "occupation",
                                                            items
                                                          );
                                                        }}
                                                      >
                                                        {items}
                                                      </div>
                                                    </Listbox.Option>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </Listbox.Options>
                                      </>
                                    );
                                  }}
                                </Listbox>
                              </div>

                              <div
                                style={{
                                  textAlign: isArabic ? "right" : "left",
                                }}
                                className="text-LightStrokActive text-MediumText mt-4"
                              >
                                {t("Country")}*
                              </div>

                              <div className="relative">
                                <Listbox>
                                  {({ open }) => {
                                    if (!open) {
                                      setSearchText("");
                                    }
                                    return (
                                      <>
                                        <Listbox.Button
                                          className={cn(
                                            "relative items-center w-[100%] text-[14px] text-LightStrokActive px-[15px] py-[10px] rounded-[7px] border-LightStrokPrimary border-[1px] flex justify-between",
                                            {
                                              "text-LightPrimary":
                                                formData.occupation,
                                            }
                                          )}
                                        >
                                          {formData.country ? (
                                            <>
                                              <div className="flex gap-3 items-center hover:bg-LightGraytext-LightStrokActive rounded-[8px] ">
                                                <div className="w-[23px] h-[18px] flex items-center">
                                                  <img
                                                    src={
                                                      formData.country.flags.svg
                                                    }
                                                    alt="jnhfr"
                                                    className="w-full "
                                                  />
                                                </div>
                                                <div className="font-DMSansFont font-normal">
                                                  {formData.country.name.common}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            t("SelectCountry")
                                          )}
                                          <img
                                            className={cn(
                                              "absolute top-[13px] transition-all",
                                              {
                                                "rotate-[180deg]": open,
                                                "left-[13px]": isArabic,
                                                "right-[13px]": !isArabic,
                                              }
                                            )}
                                            src={Arrow}
                                            alt="Arrow"
                                          />
                                        </Listbox.Button>
                                        {/* {open && ( */}
                                        <Listbox.Options
                                          className={cn("hidden", {
                                            block: open,
                                          })}
                                        >
                                          <div
                                            className={cn(
                                              "z-30 pb-[8px] absolute top-11 left-0  shadow-[0px_2px_20px_rgba(0,0,0,0.15)] bg-white w-[100%] rounded-[8px]",
                                              {
                                                'bottom-full top-["unset"]':
                                                  windowHeight < 1000,
                                              }
                                            )}
                                          >
                                            <div className="flex items-center px-4 h-[36px]">
                                              <div className="w-fit">
                                                <img
                                                  src={SearchIcon}
                                                  alt="search"
                                                  className=""
                                                />
                                              </div>
                                              <input
                                                className="border-none outline-none w-[100%] px-[10px] py-[2px] text-MediumText"
                                                placeholder={t("SearchCountry")}
                                                value={searchText}
                                                onChange={(e) =>
                                                  setSearchText(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div className="w-[100%] h-[1px] bg-[#DFE4EC]"></div>
                                            <div className=" max-h-[150px] overflow-y-auto p-2">
                                              <Listbox.Option
                                                onBlur={() => {
                                                  console.log("cfadfsdf");
                                                }}
                                                // disabled={items.unavailable}
                                              >
                                                {filteredCountries.map(
                                                  (items, index) => {
                                                    return (
                                                      <div
                                                        key={index}
                                                        className="flex gap-3 items-center px-2 py-1 hover:bg-LightGray hover:text-LightPrimary text-LightStrokActive rounded-[8px] cursor-pointer"
                                                        onClick={() => {
                                                          setFormData({
                                                            ...formData,
                                                            country: items,
                                                          });
                                                        }}
                                                      >
                                                        <div className="w-[25px] h-[18px] flex items-center">
                                                          <img
                                                            src={
                                                              items.flags.svg
                                                            }
                                                            alt="jnhfr"
                                                            className="w-[100%] h-[100%] rounded-[4px]"
                                                          />
                                                        </div>

                                                        <div
                                                          className="font-DMSansFont font-normal text-[14px] py-1"
                                                          onClick={() => {
                                                            handleFormChange(
                                                              "country",
                                                              items.name.common
                                                            );
                                                          }}
                                                        >
                                                          {items.name.common}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </Listbox.Option>
                                            </div>
                                          </div>
                                        </Listbox.Options>
                                        {/* )} */}
                                      </>
                                    );
                                  }}
                                </Listbox>
                              </div>
                            </div>
                            <button
                              disabled={isDisable || isLoading}
                              onClick={handleSubmit}
                              className={`w-full rounded-ButtonRadius border-[1px] border-bateau px-[10px] max_sm:px-[8px] py-[8px] max_sm:py-1 !mt-7  ${
                                isDisable || isLoading
                                  ? "bg-[#CED8E8]"
                                  : "bg-[#1678F2]"
                              }`}
                            >
                              <div className="F-JC-AI-CENTER font-DMSansFont font-medium text-white text-TextSmall max_sm:text-TextSmall max_esm:text-[13px]">
                                {/* {isLoading ? "Loading..." :  `{t('Request')}}` */}
                                {isLoading ? (
                                  <>
                                    <Loader />
                                  </>
                                ) : (
                                  t("Request")
                                )}
                              </div>
                            </button>
                          </div>
                        </div>
                      )}

                      {isSuccess && (
                        <div className="relative bg-white rounded-lg shadow w-full max-w-[482px]">
                          {/* Modal header */}
                          <button
                            onClick={() => {
                              hideModal();
                              setIsError(undefined);
                              setServerError(false);
                              // setValue(null);
                              setSearchText("");
                              setFormData(intitalForm);
                            }}
                            type="button"
                            className="absolute top-[16px] right-[16px] text-gray-400 bg-transparent hover:bg-gray-200  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>

                          {/* Modal Content */}
                          <div className="flex w-full items-center flex-col gap-[20px] py-[30px]">
                            <SuccessIcon />
                            <p className="text-LightPrimary text-SmallsubHeading font-DMSansFont font-bold">
                              {t("SuccessTitle")}
                            </p>
                            <p className="text-LightTextParagraph max-w-[370px] text-TextSmall font-DMSansFont font-medium text-center">
                              {t("SuccessDesc")}
                            </p>
                          </div>
                        </div>
                      )}

                      {serverError && (
                        <>
                          <ServerErrorModal
                            onClose={() => {
                              hideModal();
                              setIsError(undefined);
                              setServerError(false);
                              // setValue(null);
                              setIsSuccess(false);
                              setSearchText("");
                              setFormData(intitalForm);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* Main modal */}
      {/* {isModalOpen && (
        
      )} */}
    </div>
  );
};
const getTranslatedList = (t) => {
  // Define your lists here
  const lists = [
    t("Agriculture"),
    t("Construction"),
    t("Finance"),
    t("Manufacturing"),
    t("Nonprofits"),
    t("ProfessionalServices"),
    t("PropertyManagement"),
    t("Retail&Ecommerce"),
    t("Software&Technology"),
    t("Transportation"),
    t("Wholesale"),
    t("Other"),
  ];

  return lists;
};

export default RequestModal;

const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
  >
    <path
      d="M30 56.875C15.175 56.875 3.125 44.825 3.125 30C3.125 15.175 15.175 3.125 30 3.125C44.825 3.125 56.875 15.175 56.875 30C56.875 44.825 44.825 56.875 30 56.875ZM30 6.875C17.25 6.875 6.875 17.25 6.875 30C6.875 42.75 17.25 53.125 30 53.125C42.75 53.125 53.125 42.75 53.125 30C53.125 17.25 42.75 6.875 30 6.875Z"
      fill="#23B339"
    />
    <path
      d="M26.4496 38.95C25.9496 38.95 25.4746 38.75 25.1246 38.4L18.0496 31.325C17.3246 30.6 17.3246 29.4 18.0496 28.675C18.7746 27.95 19.9746 27.95 20.6996 28.675L26.4496 34.425L39.2996 21.575C40.0246 20.85 41.2246 20.85 41.9496 21.575C42.6746 22.3 42.6746 23.5 41.9496 24.225L27.7746 38.4C27.4246 38.75 26.9496 38.95 26.4496 38.95Z"
      fill="#23B339"
    />
  </svg>
);

export const ErrorIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.99967 14.6666C11.6663 14.6666 14.6663 11.6666 14.6663 7.99992C14.6663 4.33325 11.6663 1.33325 7.99967 1.33325C4.33301 1.33325 1.33301 4.33325 1.33301 7.99992C1.33301 11.6666 4.33301 14.6666 7.99967 14.6666Z"
      stroke="#FF0000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 5.33325V8.66659"
      stroke="#FF0000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99609 10.6667H8.00208"
      stroke="#FF0000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ServerErrorModal = ({ onClose }) => (
  <div className="relative bg-white rounded-lg shadow w-full max-w-[450px]">
    {/* Modal header */}
    <button
      onClick={onClose}
      type="button"
      className="absolute top-[16px] right-[16px] text-gray-400 bg-transparent hover:bg-gray-200  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"

      // className="text-gray-400 bg-transparent hover:bg-gray-200  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
    >
      <svg
        className="w-3 h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
      <span className="sr-only">Close modal</span>
    </button>
    {/* <div className="flex items-center justify-between px-4 pt-2 md:p-5 rounded-t ">
      <h3 className="text-xl font-semibold text-gray-900 dark:text-white"></h3>
    </div> */}

    {/* Modal Content */}
    <div className="flex w-full items-center flex-col gap-[20px] py-[30px]">
      <ErrorIcon style={{ width: "60px", height: "60px" }} />
      <p className="text-ErrorText text-SmallsubHeading font-DMSansFont font-bold">
        Something Went Wrong !
      </p>
    </div>
  </div>
);

const Loader = () => (
  <svg
    class="animate-SpinLoader m-[2px] mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
