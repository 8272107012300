import React, { useState } from "react";
import { sendReferMail } from "../../../../Exe-api/refer-api";
import { ButtonExeFilled } from "../Button";
import InputField, { SuccessMsg } from "../InputField";
import SpinnerLoader from "../../../../utils/LoaderCommon";
import { t } from "i18next";

const ReferByLinkEmail = ({ title, onCopy }) => {
  const [inputRefer, setInputRefer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const initialSuccess = { email: undefined };
  const [successMsg, setSuccessMsg] = useState(initialSuccess);
  const initial = { email: undefined };
  const [errorMsg, setErrorMsg] = useState(initial);

  const referLinkMail = async () => {
    if (inputRefer.trim()?.length === 0) return;
    setIsLoading(true);
    try {
      const res = await sendReferMail({
        email: inputRefer,
      });
      if (res?.Error || res?.detail) {
        console.log(res);
        setErrorMsg({
          ...errorMsg,
          email: <p dangerouslySetInnerHTML={{ __html: res.Message }} />,
        });
        setSuccessMsg(initialSuccess);
        return;
      }
      setInputRefer("");
      setSuccessMsg({ ...successMsg, email: t("success_sent") });
      setTimeout(() => {
        setSuccessMsg(initialSuccess);
      }, 6000);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <p className="text-[16px] leading-[20px] font-bold text-LightPrimary">
          {t("refer_by_email_link")}
        </p>
        <button
          onClick={onCopy}
          className="text-[12px] leading-[16px] font-medium text-LightPrimaryActive hover:text-DarkHover"
        >
          {title}
        </button>
      </div>
      <div className="flex gap-x-[16px] w-full mt-[16px]">
        <div className="flex flex-col w-full">
          <InputField
            type="email"
            className="w-full"
            value={inputRefer}
            isDisabled={isLoading}
            error={errorMsg.email}
            placeholder={t("email_separeted")}
            onChange={(e) => {
              setErrorMsg(initial);
              setInputRefer(e.target.value);
            }}
          />
          {successMsg.email && <SuccessMsg successMsg={successMsg.email} />}
        </div>
        <div className="w-[170px]">
          <ButtonExeFilled
            isDisable={isLoading}
            fontColor={"#FFFFFF"}
            text={isLoading ? <SpinnerLoader /> : t("refer")}
            onClick={referLinkMail}
            hoverCss={"hover:!bg-DarkHover !h-[44px]"}
          />
        </div>
      </div>
    </div>
  );
};

export default ReferByLinkEmail;
