import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import MobileMenu from "../Pages/Home/MobileMenu";
import { useOnClickOutside } from "./OutSideClick";
import { useLocation, useNavigate } from "react-router-dom";

const Layout = ({
  children,
  setModalOpen,
  isModalOpen,
  toggleModal,
  hideModal,
  referrer,
}) => {
  const { t, i18n } = useTranslation();
  const nodeRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const outsideRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  useOnClickOutside(outsideRef, () => setIsMobileMenuOpen(false));
  const [activeLangauge, setActiveLangauge] = useState("عربي");
  const [langaugeList] = useState([t("English"), t("Arabic")]);

  // useEffect(() => {
  //   setActiveLangauge((prevValue) => prevValue);
  // }, [t, activeLangauge]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.
  const isLang = localStorage.getItem("lang");
  useEffect(() => {
    const getLang = document.getElementById('main-app');
    if (isLang) {
      if (isLang == 'ar') {
        i18n.changeLanguage('ar');
        setActiveLangauge('English');
        getLang.dir = 'rtl';
      } else {
        getLang.dir = 'ltr';
        i18n.changeLanguage('en');
        setActiveLangauge('عربي');
      }
    } else {
      localStorage.setItem('lang', 'en');
      getLang.dir = 'ltr';
    }
  }, [isLang]);

  const chooseLanguage = (value) => {   
    // e.preventDefault();
    i18n.changeLanguage(value); // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
    setSelectedLanguage(value);
    localStorage.setItem("lang", value);
    const abc = document.getElementById("main-app");
    if (value === "en") {
      abc.dir = "ltr";
    } else {
      abc.dir = "rtl";
    }
  };

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", changeBackground);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", changeBackground);
      };
    }
  }, []);

  const handleClickScroll = (id) => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 600);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuOpen]);
  const scrollDirection = useScrollDirection();
  return (
    <>
      <div className="relative w-full">
        <div
          className={`sticky ${
            scrollDirection === 'down' ? '-top-24' : 'top-0'
          } z-50 transition-all duration-500`}
        >
          <div
            className={` relative m-auto w-full ${
              navbarChangeColor
                ? 'bg-white shadow-sm transition'
                : 'bg-white transition'
            }`}
          >
            <Navbar
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              activeLangauge={activeLangauge}
              setActiveLangauge={setActiveLangauge}
              langaugeList={langaugeList}
              handleClickScroll={handleClickScroll}
              selectedLanguage={selectedLanguage}
              onChangeFun={chooseLanguage}
              setModalOpen={setModalOpen}
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              hideModal={hideModal}
            />

            <CSSTransition
              in={isMobileMenuOpen}
              // nodeRef={nodeRef}
              timeout={300}
              classNames="menu"
              type={'out-in' | 'in-out'}
              unmountOnExit
              onEnter={() => setIsMobileMenuOpen(true)}
              onExited={() => setIsMobileMenuOpen(false)}
            >
              <MobileMenu
                nodeRef={nodeRef}
                outsideRef={outsideRef}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                activeLangauge={activeLangauge}
                setActiveLangauge={setActiveLangauge}
                langaugeList={langaugeList}
                handleClickScroll={handleClickScroll}
                onChangeFun={chooseLanguage}
                toggleModal={toggleModal}
              />
            </CSSTransition>

            {/* {!navbarChangeColor && (
              <>
                <div className="z-30 absolute w-[2px] top-0 left-[10%] h-[80px] bg-LightStrokPrimary opacity-[0.14]"></div>
                <div className="z-30 absolute w-[2px] top-0 left-[30%] h-[80px] bg-LightStrokPrimary opacity-[0.14]"></div>
                <div className="z-30 absolute w-[2px] top-0 left-[50%] max_md:left-[62%] h-[80px] bg-LightStrokPrimary opacity-[0.14]"></div>
                <div className="z-30 absolute w-[2px] top-0 left-[90%] h-[80px] bg-LightStrokPrimary opacity-[0.14]"></div>
              </>
            )} */}
          </div>
        </div>
        <div>{children}</div>
        {location.pathname !== '/chrome/uninstall-feedback' && (
          <>
            {location.pathname !== '/chrome/uninstall-success' && (
              <div>
                <Footer
                  handleClickScroll={handleClickScroll}
                  referrer={referrer}
                />
              </div>
            )}
          </>
        )}
        
      </div>
    </>
  );
};

export default Layout;

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = React.useState(null);

  React.useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}
